<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Darío Valencia Agudelo                                 ###### -->
<!-- ###### @date: Enero 2025                                                        ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <article class="parametrizacion" >
      <header>
        <h2>Parametrización de Contratos y Expedientes</h2>
        <article>
          <div>            
            <v-text-field
              v-model="filtros.codigo"
              :counter="10"              
              label="Bodega"
              hide-details
              required
            ></v-text-field>
          </div>

          <div>            
            <v-text-field
              v-model="filtros.producto"
              :counter="10"              
              label="Contrato"
              hide-details
              required
            ></v-text-field>
          </div>

          <div>            
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="filtros.fechaDisponibilidad"
                    :counter="10"                    
                    label="Fecha"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    required
                    ></v-text-field>
                </template>
                <v-date-picker 
                    v-model="filtros.fechaDisponibilidad" 
                ></v-date-picker>
            </v-menu>
          </div>
        </article>
      </header>
      <section>
        <article>
          <div> 
            <v-btn variant="elevated" color="primary" @click="abrirDialogo2">
              Nuevo Parametrización de Contrato
            </v-btn>
          </div>
        </article>        
        <v-container>
            <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="20"
            class="elevation-1"
            >
                <template v-slot:headerCell="{ header }">
                    <th
                    :class="['custom-header']"
                    class="text-left"
                    >
                    {{ header.text }}
                    </th>
                </template>
                <template v-slot:item="{ item, index }">
                    <tr :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
                    <td>{{ item.contrato.idContrato }}</td>
                    <td>{{ item.contrato.nombreContrato }}</td>
                    <td>{{ item.contrato.tipoMovimiento }}</td>
                    <td>{{ item.contrato.empresa.nombre }}</td>
                    <td>{{ item.contrato.empresa.abreviatura }}</td>
                    <td>                      
                      <v-btn small @click="abrirDialogo(item)">Ver</v-btn>
                    </td>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    append-icon="mdi-magnify"
                    ></v-text-field>
                </template>

                <template v-slot:item.estado="{ item }">
                    <v-chip
                    :color="item.estado === 'Disponible' ? 'green' : 'red'"
                    dark
                    >
                    {{ item.estado === 1 ? 'Disponible' : 'No disponible' }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-container>
      </section>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            Parametrizar Archivos - Contrato {{ contratoSeleccionado?.contrato?.nombreContrato }}
          </v-card-title>
          <v-card-text>
            <h3>Agregar Nuevo Archivo</h3>
            <v-form ref="archivoForm" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="nuevoArchivo.tipoArchivo"
                      :items="tipoArchivos"
                      item-text="label"
                      item-value="value"
                      label="Tipo de Archivo"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="nuevoArchivo.nombreArchivoDescargar"
                      label="Nombre del Archivo a Guardar"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="nuevoArchivo.obligatorio"
                      :items="['Sí', 'No']"
                      label="Obligatorio"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="nuevoArchivo.observacion"
                      label="Observación"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="guardarNuevoArchivo"
                >
                  Guardar Nuevo Archivo
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-data-table
              v-if="contratoSeleccionado?.archivosContratoExpediente.length>0"
              :headers="archivoHeaders"
              :items="contratoSeleccionado?.archivosContratoExpediente || []"
              class="elevation-1 mb-5"
              item-value="nombreArchivoDescargar"
            >
              <!-- Slot por defecto (opcional) -->
              <template v-slot:body>
                <tr v-for="(item, index) in contratoSeleccionado?.archivosContratoExpediente" :key="index">
                  <td>{{ parseTipoArchivo(item.tipoArchivo) }}</td>
                  <td>{{ item.nombreArchivoDescargar }}</td>
                  <td>{{ item.obligatorio ? 'Sí' : 'No' }}</td>
                  <td>{{ item.observacion }}</td>
                  <td>
                    <v-btn
                      color="red"
                      icon
                      @click="eliminarArchivo(item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="cerrarDialogo">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="500px">
        <v-card>
          <v-card-title class="text-h6">
            Crear Contrato con Expediente
          </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <!-- Select para elegir el contrato -->
              <v-select
                v-model="contratoSeleccionado2"
                :items="contratos"
                item-text="nombreContrato"
                item-value="idContrato"
                label="Seleccionar Contrato"
                dense
                outlined
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="crearContrato">
              Crear Contrato con Expediente
            </v-btn>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="cerrarModal2">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </article>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import Swal from 'sweetalert2';
  
  
  export default {
    name: 'Index',
    data: () => ({
        search: '',
        dialog: false,
        dialog2: false,
        headers: [
            { text: 'Id Contrato', value: 'idContrato' },
            { text: 'Nombre Contrato', value: 'Nombre Contrato' },
            { text: 'Tipo Movimiento', value: 'Tipo Movimiento' },
            { text: 'Empresa', value: 'Empresa' },
            { text: 'Abreviatura', value: 'Abreviatura' },
            { text: 'Acciones', value: 'actions', sortable: false }
        ],
        archivoHeaders: [
          { text: "Tipo de Archivo", value: "tipoArchivo" },
          { text: "Nombre del Archivo", value: "nombreArchivoDescargar" },
          { text: "Obligatorio", value: "obligatorio" },
          { text: "Observación", value: "observacion" },
          { text: "Acciones", value: "acciones", sortable: false },
        ],
        tipoArchivos: [
          { value: "ATTACHMENT", label: "Fórmula Médica" },
          { value: "INVOICE", label: "Factura" },
          { value: "HISTORY", label: "Hoja Entrega" },
          { value: "ORDER", label: "Autorización" },
          { value: "ATTENTION", label: "Comprobante" },
          { value: "ATTACHMENTMIPRES", label: "Fórmula Mipres" },
          { value: "ADRES", label: "Adres" },
          { value: "OTHER", label: "Historia Clínica" },
        ],
        tipoArchivosMap: {
          ATTACHMENT: "Fórmula Médica",
          INVOICE: "Factura",
          HISTORY: "Hoja Entrega",
          ORDER: "Autorización",
          ATTENTION: "Comprobante",
          ATTACHMENTMIPRES: "Fórmula Mipres",
          ADRES: "Adres",
          OTHER: "Historia Clínica",
        },
        nuevoArchivo: {
          tipoArchivo: "ATTACHMENT",
          nombreArchivoDescargar: "",
          obligatorio: "Sí",
          observacion: "",
        },
        items: [            
        ],
        filtros: {
          producto:"",
          codigo:"",
          laboratorio:"",
          fecha:"",
          carta:"",
          fechaDisponibilidad:""
        },   
        menu: false,
        menu2: false,
        tab: null,
        rolesKeycloak: null,
        codigosEmpresas: [],
        dataEmpresas: [],
        usuario: "",
        contratoSeleccionado: null,
        contratoSeleccionado2: null,
        contratos: []
    }),
    mounted() {
      this.auth.roles = [];
      this.rolesKeycloak = this.auth.keycloak.realmAccess.roles;
      this.usuario = this.auth.username;
      this.empresasUsuario();
      this.getContratos();
    },
    computed: {
      ...mapState(["auth", "enterprise"]),
        filteredItems() {
            if (!this.search) {
                return this.items;
            }
            const search = this.search.toLowerCase();
            console.log(search);
            return this.items.filter(item => {
                return (
                item.laboratorio.toLowerCase().includes(search)
                );
            });
        },
        filteredItems2() {
            if (!this.search) {
                return this.productos;
            }
            const search = this.search.toLowerCase();
            console.log(search);
            return this.productos.filter(item => {
                return (
                item.nombreProducto.toLowerCase().includes(search)
                );
            });
        }
    },
  
    methods: {
      ...mapMutations(["setEnterprise"]),
      
      async empresasUsuario() {
        const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
          .then(({ data }) => this.codigosEmpresas = data)
          .catch(err => console.log(err))
        console.log(codEmpresas);
        
        if (codEmpresas && codEmpresas.length > 0) {
          await this.$http.post('msa-setting/api/laboratorio/list', this.codigosEmpresas)
            .then(({ data }) => this.dataEmpresas = data)
            .catch(err => console.log(err))
        }
      },
      parseTipoArchivo(tipoArchivo) {
        return this.tipoArchivosMap[tipoArchivo] || "Desconocido";
      },
      changeView() {
        this.view= !this.view;
      },

      async getContratos() {
        
        this.$http
          .get("msa-process-file/api/contratoexpediente/listarAllContratoExpediente?idEmpresa=1&codigoBodega=3613")
          .then((result) => {
            console.log(result);
              this.items=result.data;
              this.showSuccessMessage = true;
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          }); 
      },

      toEnterprise(enterprise) {
        this.auth.roles = this.rolesKeycloak;
        this.setEnterprise(enterprise)
      },
      seeItem(item) {
        console.log(item);        
        // this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      editItem(item) {
        console.log(item);        
        this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      seePdf(item) {
        console.log(item);        
        this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      deleteItem(item) {
        console.log(item);  
        Swal.fire({
          title: '¿Estás seguro?',
          text: "¿En realidad deseas eliminar esta carta?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {

            this.$http
            .delete("msa-administration/api/carta/"+item.id)
            .then((result) => {
              console.log(result);
              this.items = this.items.filter((it)=> it.id!=item.id);
              Swal.fire(
                'Eliminado',
                'El expediente ha sido eliminada correctamente.',
                'success'
              );
            }) 
            .catch((error) => {
                console.log(error?.response?.data);
            }); 

          }
        });      
        // this.$router.push({ path: '/modules/carta/'+item.id }); 
      },
      
    redirectToCarta() {

    },
    crearContrato() {
      let data= {    
        "bodega": {
            "bodegasId": {
                "idEmpresa": 1,
                "codigoBodega": 3613
            }
        },
        "contrato": {
            "idContrato": this.contratoSeleccionado2
        } 
      }
      console.log(this.contratoSeleccionado2);
      console.log(data);
      
      this.$http
        .post("msa-process-file/api/contratoexpediente/crearContratoExpediente", data)
        .then((result) => {
          console.log(result);
          this.contratos = result.data;
          this.dialog2 = false;
          Swal.fire(
            'Contrato Expediente',
            'El contrato ha sido inicializado.',
            'success'
          );
          this.getContratos();
        }) 
        .catch((error) => {
            console.log(error?.response?.data);
        });

      
    },

    abrirDialogo2() {
      this.dialog2 = true;
      this.$http
        .get("msa-process-file/api/contrato/listarContratos")
        .then((result) => {
          console.log(result);
          this.contratos = result.data;
        }) 
        .catch((error) => {
            console.log(error?.response?.data);
        }); 
    },
    abrirDialogo(contrato) {
      console.log(contrato);
      this.contratoSeleccionado = contrato;
      this.dialog = true;
      // this.$http
      //   .get("http://localhost:8771/msa-process-file/api/contratoexpediente/listarOneContratoExpediente?idEmpresa=1&codigoBodega=3613&idContrato="+contrato.idContratoExpediente)
      //   // .get("http://localhost:8771/msa-process-file/api/contratoexpediente/listarOneContratoExpediente?idEmpresa=1&codigoBodega=3613&idContrato="+contrato.idContrato)
      //   .then((result) => {
      //     console.log(result);
      //     this.contratoSeleccionado = result.data[0]?result.data[0]:[];
      //     this.dialog = true;
      //   }) 
      //   .catch((error) => {
      //       console.log(error?.response?.data);
      //   }); 

    },
    cerrarModal2() {
      this.dialog2 = false;
    },
    cerrarDialogo() {
      this.dialog = false;
      this.reiniciarNuevoArchivo();
    },
    reiniciarNuevoArchivo() {
      this.nuevoArchivo = {
        tipoArchivo: "ATTACHMENT",
        nombreArchivoDescargar: "",
        obligatorio: "Sí",
        observacion: "",
      };
    },
    guardarNuevoArchivo() {

      const existe = this.contratoSeleccionado.archivosContratoExpediente.some(
        (archivo) => archivo.tipoArchivo === this.nuevoArchivo.tipoArchivo
      );

      if (existe) {
        Swal.fire(
          'Contrato Expediente',
          'El tipo de archivo, ya existe para este contrato.',
          'warning'
        );
        return;
      }

      let data ={
        ...this.nuevoArchivo,
        "contratoExpediente": {
            "idContratoExpediente":this.contratoSeleccionado.idContratoExpediente
        }
      };
      data.obligatorio=(data.obligatorio=="Sí");
      console.log(data);
      this.$http
        .post("http://localhost:8771/msa-process-file/api/contratoexpediente/crearArchivoContratoExpediente", data)
        // .get("http://localhost:8771/msa-process-file/api/contratoexpediente/listarOneContratoExpediente?idEmpresa=1&codigoBodega=3613&idContrato="+contrato.idContrato)
        .then((result) => {
          console.log(result);
          this.contratoSeleccionado.archivosContratoExpediente.push(result.data);
          this.reiniciarNuevoArchivo();
          Swal.fire(
            'Contrato Expediente',
            'El archivo ha sido almacenado.',
            'success'
          );
        }) 
        .catch((error) => {
            console.log(error?.response?.data);
        }); 
    },
    eliminarArchivo(archivo) {
      Swal.fire({
          title: '¿Estás seguro?',
          text: "¿En realidad deseas eliminar este archivo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http
            .delete("msa-process-file/api/contratoexpediente/eliminarArchivoContratoExpediente/"+archivo.idArchivoContratoExpendiente)
            .then((result) => {
              console.log(result);
              this.contratoSeleccionado.archivosContratoExpediente = this.contratoSeleccionado.archivosContratoExpediente.filter(
                (item) => item !== archivo
              );
              Swal.fire(
                'Eliminado',
                'El archivo ha sido eliminada correctamente.',
                'success'
              );
            }) 
            .catch((error) => {
                console.log(error?.response?.data);
            }); 

          }
        });  
    },
    }
  }
  </script>
  
  <style scoped>
  
  ul, ol {
    list-style: none;
  }

  .v-data-table-header {
    background-color: #f2f2f2; 
    color: #333; 
    font-weight: bold;
    }

    .row-even {
    background-color: #fafafa; 
    }

    .row-odd {
    background-color: #f5f5f5; 
    }

    .v-chip {
    font-size: 0.875rem; 
    }
  
  article.parametrizacion {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
  
  
  article.parametrizacion > header {
    margin-bottom: 1rem;
    width: 90%;
  }
  
  article.parametrizacion > header h2 {
    padding-left: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  article.parametrizacion > header > article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  article.parametrizacion > header > article div {
    margin: 0 1rem;
    width: 100%;
  }
  
  article.parametrizacion > header > article div label {
    margin-left: .5rem;
    width: 100%;
  }
  
  article.parametrizacion i {
    margin-right: 0 !important;
  }
  
  article.parametrizacion > section .container{
    width: 100%;
    max-width: 100% !important;
  }

  article.parametrizacion > section {
    width: 100%;
  }

  article.parametrizacion > section > article{
    display: flex; 
    justify-content: flex-end;
    width: 90%;   
  }
  
  article.parametrizacion > section > article div{
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  article.parametrizacion > section ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: stretch
  }
  
  </style>